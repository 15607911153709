import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Routes } from './utils'
import { Home, Login, Error } from './pages'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={Routes.LOGIN} exact>
          <Login />
        </Route>
        <Route path={Routes.HOME} exact>
          <Home />
        </Route>
        <Route path={Routes.ERROR} exact>
          <Error />
        </Route>
        <Route path="*">
          <Redirect to={Routes.HOME} />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
