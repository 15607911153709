const createConfig = (env: string | undefined) => {
  switch (env) {
    case 'staging':
      throw new Error('staging is not enabled')
    case 'prod':
      return {
        env,
        authUrl: `https://apse1.api.affinidi.io/vpa`,
        hostUrl: `https://oidc-vp-adapter-frontend.affinidi.com`,
        aivExtensionId: `fejpjjkbaklcdcibmkbmpanjbiihclon`,
        aivExtensionUrl:
          'https://chrome.google.com/webstore/detail/affinidi-vault/fejpjjkbaklcdcibmkbmpanjbiihclon',
      }
    default:
      return {
        env: 'dev',
        authUrl: `https://apse1.dev.api.affinidi.io/vpa`,
        hostUrl: `https://oidc-vp-adapter-frontend.dev.affinidi.io`,
        aivExtensionId: `fejpjjkbaklcdcibmkbmpanjbiihclon`,
        aivExtensionUrl:
          'https://chrome.google.com/webstore/detail/affinidi-vault/fejpjjkbaklcdcibmkbmpanjbiihclon',
      }
  }
}

export default createConfig(process.env.REACT_APP_ENVIRONMENT)
