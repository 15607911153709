import { AivExtensionClient } from '@affinidi/client-aiv-extension'
import { createContext, ReactElement, ReactNode, useCallback, useContext } from 'react'
import { useAsyncCallback } from 'react-async-hook'
import { v4 as uuidv4 } from 'uuid'
import config from '../../config'
import oidcVpAdapter from '../../services/oidc-vp-adapter'
import {
  LoginSessionAcceptResponseInput,
  LoginSessionAcceptResponseOutput,
  LoginSessionDto,
} from '../../services/oidc-vp-adapter/api'

const AFFINIDI_AUTH_URL = `chrome-extension://${config.aivExtensionId}/login.html`
const client = new AivExtensionClient({ chromeExtensionId: config.aivExtensionId })

type ActiveLogin = {
  state: string
  nonce: string
  loginSessionId: string
}

type Sessions = {
  activeSessions: Record<string, ActiveLogin>
}

class LoginSessionState {
  private static readonly KEY = 'login:state'

  private getSessions(): Sessions {
    return JSON.parse(localStorage.getItem(LoginSessionState.KEY) ?? '{ "activeSessions": {} }')
  }

  private setSessions(sessions: Sessions): void {
    localStorage.setItem(LoginSessionState.KEY, JSON.stringify(sessions))
  }

  set(activeLogin: ActiveLogin) {
    const sessions = this.getSessions()
    sessions.activeSessions[activeLogin.state] = activeLogin
    this.setSessions(sessions)
  }

  get(key: string): ActiveLogin | undefined {
    return this.getSessions().activeSessions[key]
  }

  remove(key: string): void {
    const items = this.getSessions()
    delete items.activeSessions[key]
    this.setSessions(items)
  }
}

export const activeLoginStorage = new LoginSessionState()

const extractNonceFromVPToken = (vpToken: string): { nonce: string } => {
  let vp: any
  try {
    vp = JSON.parse(vpToken)
  } catch {
    throw new Error('could not parse vpToken')
  }

  // TODO: the vpToken maybe more complex
  const nonce = vp?.proof?.challenge
  if (!nonce) {
    throw new Error('vp did not have a proof challenge')
  }

  return { nonce }
}

const isDirectPostVersion = (version?: string): boolean => {
  if (version) {
    const [majorVer, minorVer] = (version as string).split('.').map(Number)
    if (majorVer > 1 || minorVer >= 6) {
      return true
    }
  }
  return false
}

const useLogin = () => {
  const requestVpFromExtension = useAsyncCallback(
    useCallback(async ({ id: loginSessionId, authorizationRequest }: LoginSessionDto) => {
      const nonce = uuidv4()
      const claims = {
        id_token: {
          email: null,
        },
        vp_token: {
          presentation_definition: JSON.parse(authorizationRequest.presentationDefinition),
        },
      }

      const url = new URL(AFFINIDI_AUTH_URL)
      url.searchParams.set('response_type', 'vp_token id_token')
      url.searchParams.set('client_id', authorizationRequest.clientId)
      url.searchParams.set('response_mode', 'query')
      let version
      try {
        version = await client.getVersion()
      } catch (error) {
        version = undefined
      }
      const applyDirectPost = isDirectPostVersion(version)
      if (applyDirectPost) {
        url.searchParams.set('response_mode', 'direct_post')
        url.searchParams.set(
          'accept_response_uri',
          `${config.authUrl}/v1/login/sessions/${loginSessionId}/accept-response`,
        )
        url.searchParams.set(
          'reject_response_uri',
          `${config.authUrl}/v1/login/sessions/${loginSessionId}/reject-response`,
        )
        url.searchParams.set('redirect_uri', config.hostUrl)
      }

      url.searchParams.set('scope', 'openid')
      url.searchParams.set('claims', JSON.stringify(claims))
      url.searchParams.set('state', authorizationRequest.state)
      url.searchParams.set('nonce', nonce)

      activeLoginStorage.set({ loginSessionId, nonce, state: authorizationRequest.state })

      return url.toString()
    }, []),
  )

  const sendVpResponseToBackend = useAsyncCallback(
    useCallback(
      async (input: LoginSessionAcceptResponseInput): Promise<LoginSessionAcceptResponseOutput> => {
        const activeLogin = activeLoginStorage.get(input.state)
        if (activeLogin?.state !== input.state) {
          throw new Error('Extension returned invalid state')
        }

        const { nonce } = extractNonceFromVPToken(input.vp_token)
        if (activeLogin?.nonce !== nonce) {
          throw new Error('Extension returned vp token for a different challenge')
        }

        const result = await oidcVpAdapter.loginSessionAcceptResponse(
          { sessionId: activeLogin.loginSessionId },
          input,
        )

        activeLoginStorage.remove(input.state)

        return result
      },
      [],
    ),
  )

  return {
    requestVpFromExtension,
    sendVpResponseToBackend,
  }
}

const LoginContext = createContext({} as ReturnType<typeof useLogin>)

export const LoginContextProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const auth = useLogin()

  return <LoginContext.Provider value={auth}>{children}</LoginContext.Provider>
}

export const useLoginContext = () => {
  return useContext(LoginContext)
}
