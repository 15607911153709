import { FC, FormEvent, ReactElement } from 'react'
import { SetupCardButton, SetupCardPageLayout, SpinnerPage } from '@affinidi/component-library'

type Props = {
  loading: boolean
  isExtensionInstalled: boolean
  onInstallAivExtensionHandler: (e: FormEvent) => void
}

export const LoginUI: FC<Props> = ({
  loading,
  isExtensionInstalled,
  onInstallAivExtensionHandler,
}): ReactElement => {
  if (!loading && !isExtensionInstalled) {
    return (
      <SetupCardPageLayout
        cardTitle="Install Affinidi Vault"
        cardDescription="Please install Affinidi Vault to use it for Affinidi Login."
      >
        <SetupCardButton onClick={onInstallAivExtensionHandler}>Install</SetupCardButton>
      </SetupCardPageLayout>
    )
  }
  return <SpinnerPage text="Validating..." isDark />
}
