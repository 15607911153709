import styled from 'styled-components'
import { Typography, pxToRem } from '@affinidi/component-library'

export const ErrorTitle = styled(Typography)`
  margin-bottom: ${pxToRem(16)};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.utility.danger['100']};
`

export const SupportBlock = styled(Typography)`
  margin-top: ${pxToRem(24)};
  padding-top: ${pxToRem(24)};
  border-top: 1px solid ${({ theme }) => theme.colors.brand.secondary['10']};

  & a {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`
