export enum Keys {
  Backspace = 'Backspace',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
}

export enum Routes {
  HOME = '/',
  LOGIN = '/login',
  SIGNUP = '/sign-up',
  CONFIRM_LOGIN = '/login/confirm',
  ERROR = '/error',
}

export enum AuthSteps {
  loginInvited = 'LOGIN_INITIATED',
  loginCompleted = 'LOGIN_COMPLETED',
  ChallengeLoaded = 'CHALLENGE_LOADED',
  NotStarted = 'NOT_STARTED',
  ConsentGiven = 'CONSENT_GIVEN',
}

export enum ResponseType {
  RedirectResponse = 'REDIRECT_RESPONSE',
}

export enum CustomErrors {
  InvalidClient = 'Invalid Client.',
  RequestDeclined = 'Request Declined.',
}

export enum CustomErrorDescriptions {
  InvalidClient = 'Invalid Client Credentials. Client authentication failed.',
  RequestDeclined = 'The request of the application to access the data was declined.',
}
