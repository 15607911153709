import {
  Api,
  ConsentRespondInput,
  ConsentRespondOutput,
  LoginSessionAcceptResponseInput,
  LoginSessionAcceptResponseOutput,
  LoginSessionForIDPInput,
  LoginSessionForIDPOutput,
} from './api'
import config from '../../config'
import { ResponseType } from '../../utils'

export class OidcVpAdapter {
  constructor(
    private readonly api = new Api({
      baseUrl: config.authUrl,
      baseApiParams: {
        mode: 'cors',
      },
    }),
  ) {}

  async loginSessionForIdp(input: LoginSessionForIDPInput): Promise<LoginSessionForIDPOutput> {
    const result = await this.api.v1.loginSessionForIdp(input)

    return result.data
  }

  async loginSessionAcceptResponse(
    { sessionId }: { sessionId: string },
    input: LoginSessionAcceptResponseInput,
  ): Promise<LoginSessionAcceptResponseOutput> {
    const result = await this.api.v1.loginSessionAcceptResponse(sessionId, input)

    return result.data
  }

  async consentRequest(consentChallenge: string): Promise<{ type: ResponseType; to: string }> {
    const result = await this.api.v1.consentRequest({ consent_challenge: consentChallenge })
    const to = result.headers.get('location')

    if (result.status !== 307 || !to) {
      throw new Error(`expected status to be 307 but was '${result.status}'`)
    }

    return { type: ResponseType.RedirectResponse, to }
  }

  async consentRespond(input: ConsentRespondInput): Promise<ConsentRespondOutput> {
    const result = await this.api.v1.consentRespond(input)

    return result.data
  }
}

export default new OidcVpAdapter()
