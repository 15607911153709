import { ReactElement } from 'react'
import {
  SetupCardPageLayout,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@affinidi/component-library'

import { CustomErrors, CustomErrorDescriptions } from '../../utils'
import * as S from './Error.styled'

type Props = {
  description?: string
}

export const Error = ({ description }: Props): ReactElement => {
  const { getValueByScreenSize } = useMediaQuery()

  const pVariant = getValueByScreenSize<TypographyProps['variant']>({
    default: 'p2',
    desktopXL: 'p1',
  })

  const urlParams = new URLSearchParams(window.location.search)
  let error = urlParams.get('error') ?? 'Unknown error.'
  let errorDescription = urlParams.get('error_description') ?? 'An unidentified error has occurred.'

  // see list of possible errors here https://github.com/ory/fosite/blob/8098e48b2e0935855b61f3d7f924596620fdfc82/errors.go#L225
  // here we can customize errors from the link:
  switch (error) {
    case 'invalid_client':
      errorDescription = CustomErrorDescriptions.InvalidClient
      error = CustomErrors.InvalidClient
      break
    case 'access_denied':
      if (errorDescription === 'request_declined') {
        errorDescription = CustomErrorDescriptions.RequestDeclined
        error = CustomErrors.RequestDeclined
      }
      break
  }

  return (
    <SetupCardPageLayout cardTitle="Error">
      <S.ErrorTitle variant={pVariant}>{error}</S.ErrorTitle>
      <Typography variant={pVariant}>{description || errorDescription}</Typography>

      <S.SupportBlock variant={pVariant}>
        Please try again or raise a{' '}
        <a
          href="https://share.hsforms.com/1i-4HKZRXSsmENzXtPdIG4g8oa2v"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support Ticket
        </a>
        .
      </S.SupportBlock>
    </SetupCardPageLayout>
  )
}
