import { Component, ReactNode } from 'react'
import { Error } from '../../pages'

type ErrorBoundaryState = { error: any }

class ErrorBoundary extends Component<{ children: ReactNode }, ErrorBoundaryState> {
  constructor(props: any) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: any) {
    return { error }
  }

  componentDidCatch(error: any) {
    if (error?.error) {
      this.setState({ error: error.error })
    } else {
      this.setState({ error })
    }
  }

  get errorMessage() {
    const error = this.state.error
    if (!error) {
      return ''
    }
    if (typeof error === 'string') {
      return error
    }
    return (error.code ? `${error.code}: ${error.message}` : error.message) || 'Unexpected error'
  }

  render() {
    if (this.state.error) {
      return <Error description={this.errorMessage} />
    }
    return this.props.children
  }
}

export { ErrorBoundary }
