import { ReactElement, useCallback, useEffect } from 'react'
import { useAsync, useAsyncCallback } from 'react-async-hook'
import { AivExtensionClient } from '@affinidi/client-aiv-extension'
import { useLoginContext } from '../../contexts'
import config from '../../config'
import oidcVpAdapter from '../../services/oidc-vp-adapter'
import { LoginSessionDto } from '../../services/oidc-vp-adapter/api'
import { LoginUI } from './Login.ui'

const client = new AivExtensionClient({ chromeExtensionId: config.aivExtensionId })

const getLoginSession = async (loginChallenge: string, clientId: string) =>
  oidcVpAdapter.loginSessionForIdp({ loginChallenge, clientId })

export const Login = (): ReactElement => {
  const { requestVpFromExtension } = useLoginContext()

  const urlParams = new URLSearchParams(window.location.search)
  const loginChallenge = urlParams.get('login_challenge') ?? ''
  const clientId = urlParams.get('client_id') ?? ''
  const isExtensionCheckDisabled = urlParams.get('no-extension') === '1'

  const isExtensionInstalled = useAsync(() => {
    if (isExtensionCheckDisabled) {
      return Promise.resolve(true)
    }
    return client.isInstalled()
  }, [])

  const loginSession = useAsync(getLoginSession, [loginChallenge, clientId])
  const loginWithAffinidiHandler = useAsyncCallback(
    useCallback(
      async (session: LoginSessionDto) => {
        const redirectTo = await requestVpFromExtension.execute(session)
        window.location.replace(redirectTo)
      },
      [requestVpFromExtension.execute],
    ),
  )

  const loading =
    isExtensionInstalled.loading || loginSession.loading || loginWithAffinidiHandler.loading
  const error = isExtensionInstalled.error || loginSession.error || loginWithAffinidiHandler.error

  useEffect(() => {
    if (error) {
      throw error
    }
  }, [error])

  useEffect(() => {
    if (isExtensionInstalled.result && loginSession.result) {
      loginWithAffinidiHandler.execute(loginSession.result)
    }
  }, [isExtensionInstalled.result, loginSession.result])

  const onInstallAivExtensionHandler = useCallback(() => {
    window.location.href = config.aivExtensionUrl
  }, [])

  return (
    <LoginUI
      loading={loading}
      isExtensionInstalled={isExtensionInstalled.result ?? false}
      onInstallAivExtensionHandler={onInstallAivExtensionHandler}
    />
  )
}
